<template>
  <div>
    <b-row>
      <b-col
        md="6"
        lg="4"
      >
        <b-card
          :img-src="require('@/assets/images/slider/04.jpg')"
          img-alt="Card image cap"
          img-top
          title="Card title"
          sub-title="Esto es un subtitulo"
        >

          <b-card-text>
            Some quick example text to build on the card title and make up the bulk of
            the card's content.
          </b-card-text>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
          >
            Go Somewhere
          </b-button>
        </b-card>
      </b-col>
      <b-col
        md="6"
        lg="4"
      >
        <b-card no-body>
          <b-card-header>
            <b-card-title>Pay Amount</b-card-title>
            <b-card-title class="text-primary">
              $455.60
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <b-card-title>Card title</b-card-title>
            <b-card-sub-title>Support card subtitle</b-card-sub-title>
          </b-card-body>
          <b-img
            fluid
            :src="require('@/assets/images/slider/03.jpg')"
            alt="Card image cap"
          />
          <b-card-body>
            <b-card-text>Bear claw sesame snaps gummies chocolate.</b-card-text>
            <b-link class="card-link">
              Card link
            </b-link>
            <b-link class="card-link">
              Another link
            </b-link>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        md="6"
        lg="4"
      >
        <b-card
          title="Card title"
          sub-title="Support card subtitle"
        >
          <b-img
            fluid
            class="mb-2"
            :src="require('@/assets/images/slider/06.jpg')"
          />
          <b-card-text>Bear claw sesame snaps gummies chocolate.</b-card-text>
          <b-link class="card-link">
            Card link
          </b-link>
          <b-link class="card-link">
            Another link
          </b-link>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">

        <b-card-group
          deck
          class="mb-0"
        >
          <b-card
            :img-src="require('@/assets/images/slider/03.jpg')"
            img-alt="Card image cap"
            img-top
            no-body
          >
            <b-card-body>
              <b-card-title>Esto es un titulo</b-card-title>
              <b-card-text>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad assumenda beatae cumque cupiditate deleniti, facere fugiat harum illo iusto labore laboriosam maxime necessitatibus praesentium quam tempora veniam veritatis. Quidem, similique?
              </b-card-text>
            </b-card-body>
            <b-card-footer>
              <small class="text-muted">Última actualización hace 2 mins</small>
            </b-card-footer>
          </b-card>

          <b-card
            :img-src="require('@/assets/images/slider/03.jpg')"
            img-alt="Card image cap"
            img-top
            no-body
          >
            <b-card-body>
              <b-card-title>Esto es un titulo</b-card-title>
              <b-card-text>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad assumenda beatae cumque cupiditate deleniti, facere fugiat harum illo iusto labore laboriosam maxime necessitatibus praesentium quam tempora veniam veritatis. Quidem, similique?
              </b-card-text>
            </b-card-body>
            <b-card-footer>
              <small class="text-muted">Última actualización hace 2 mins</small>
            </b-card-footer>
          </b-card>
        </b-card-group>

      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col cols="12">
        <b-card-code
          title="Simple Form Validation"
        >
          <b-card-text>
            <span>To add validation to any input filed just wrap that field in </span>
            <code>ValidationProvider</code>
            <span> component. Next, add your validation using </span>
            <code>rules</code>
            <span> prop.</span>
          </b-card-text>

          <b-card-text>
            <span>You can get errors of this field using </span>
            <code>slot.</code>
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form>
              <b-row>
                <b-col md="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="First Name"
                      rules="required"
                    >
                      <b-form-input
                        v-model="name"
                        :state="errors.length > 0 ? false:null"
                        placeholder="First Name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      rules="required|email"
                    >
                      <b-form-input
                        v-model="emailValue"
                        :state="errors.length > 0 ? false:null"
                        type="email"
                        placeholder="Email"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-button
                    variant="primary"
                    type="submit"
                    @click.prevent="validationForm"
                  >
                    Submit
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
          <template #code>
            {{ codeSimple }}
          </template>
        </b-card-code>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import BCardCode from '@core/components/b-card-code'
import {
  BForm, BFormInput, BFormGroup, BCard, BCardHeader, BCardGroup, BButton, BCardText, BCardBody, BCardFooter, BImg, BLink, BCol, BRow, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import { codeSimple } from '../forms/form-validation/code'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BForm,
    BFormInput,
    BFormGroup,
    BCardCode,
    BCard,
    BCardHeader,
    BCardGroup,
    BCardText,
    BCardBody,
    BCardFooter,
    BImg,
    BLink,
    BCol,
    BRow,
    BCardTitle,
    BCardSubTitle,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      emailValue: '',
      name: '',
      codeSimple,
      required,
      email,
    }
  },
  setup() {
    console.log('setup')
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          alert('Se ha enviado el formulario')
        }
      })
    },
  },
}
</script>

<style>

</style>
